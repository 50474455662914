import React, {useState, useEffect}  from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';

import {
    MdMenu
} from 'react-icons/md';


const Header = ({ changeHeader }) => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 350) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            instagram: file(relativePath: { eq: "insta.png" }) {
                childImageSharp {
                    fluid(maxWidth: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            facebook: file(relativePath: { eq: "facebook.png" }) {
                childImageSharp {
                    fluid(maxWidth: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            whatsapp: file(relativePath: { eq: "whatsapp.png" }) {
                childImageSharp {
                    fluid(maxWidth: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const [btnWs, setBtnWs] = useState('https://api.whatsapp.com/send?phone=5804121645982&text=Hola%20me%20gustaría%20hablar%20sobre%20tus%20servicios');

    useEffect(() => {
        setTimeout(() => {
            if (typeof window !== `undefined`) {

                if( navigator.userAgent.match(/Android/i)
                 || navigator.userAgent.match(/webOS/i)
                 || navigator.userAgent.match(/iPhone/i)
                 || navigator.userAgent.match(/iPad/i)
                 || navigator.userAgent.match(/iPod/i)
                 || navigator.userAgent.match(/BlackBerry/i)
                 || navigator.userAgent.match(/Windows Phone/i)){

                    setBtnWs('https://api.whatsapp.com/send?phone=5804121645982&text=Hola%20me%20gustaría%20hablar%20sobre%20tus%20servicios');

                }else{

                    setBtnWs('https://web.whatsapp.com/send?phone=5804121645982&text=Hola%20me%20gustaría%20hablar%20sobre%20tus%20servicios');

                }
            }

        }, 2000);
    }, []);

    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light site-navbar-target scrolled awake" id="ftco-navbar">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <span>
                            <img src={`${data.logo.childImageSharp.fluid.src}`} alt="logo Metalinks" style={{ marginTop:'-21px', width: '200px', height: '60px' }} />
                        </span>
                    </a>
                    <button className="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <MdMenu color="7d7d7d" size="1.5em" />
                    </button>


                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav nav ml-auto">
                            <li className="nav-item">
                                {(changeHeader === 1)
                                    ?
                                    <a href={'javascript:void(0);'}
                                        onClick={() => scrollTo('#home-section')}
                                        className="nav-link">
                                        <span>Inicio</span>
                                    </a>
                                    :
                                    <Link to='/'
                                        className="nav-link"><span>Inicio</span>
                                    </Link>
                                }

                            </li>

                            <li className="nav-item">
                                {(changeHeader === 1)
                                    ?
                                    <a href={'javascript:void(0);'}
                                        onClick={() => scrollTo('#nosotros')}
                                        className="nav-link">
                                        <span>Nosotros</span>
                                    </a>
                                    :
                                    <Link to='/#nosotros'
                                        className="nav-link"><span>Nosotros</span>
                                    </Link>
                                }
                            </li>
                            <li className="nav-item">
                                {(changeHeader === 1)
                                    ?
                                    <a href={'javascript:void(0);'}
                                        onClick={() => scrollTo('#products')}
                                        className="nav-link">
                                        <span>Productos</span>
                                    </a>
                                    :
                                    <Link to='/#products'
                                        className="nav-link"><span>Productos</span>
                                    </Link>
                                }
                            </li>

                            <li className="nav-item ">
                                {(changeHeader === 1)
                                    ?
                                    <a href={'javascript:void(0);'}
                                        onClick={() => scrollTo('#aliados')}
                                        className="nav-link">
                                        <span>Aliados</span>
                                    </a>
                                    :
                                    <Link to='/#aliados'
                                        className="nav-link"><span>Aliados</span>
                                    </Link>
                                }
                            </li>

                            <li className="nav-item">
                                {(changeHeader === 1)
                                    ?
                                    <a href={'javascript:void(0);'}
                                        onClick={() => scrollTo('#registrate')}
                                        className="nav-link">
                                        <span>Alcance</span>
                                    </a>
                                    :
                                    <Link to='/#registrate'
                                        className="nav-link"><span>Alcance</span>
                                    </Link>
                                }
                            </li>

                            {/* <li className="nav-item">
                                {(changeHeader === 1)
                                    ?
                                    <a href={'javascript:void(0);'}
                                        onClick={() => scrollTo('#categorias')}
                                        className="nav-link">
                                        <span>Categorías</span>
                                    </a>
                                    :
                                    <Link to='/#categorias'
                                        className="nav-link"><span>Categorías</span>
                                    </Link>
                                }
                            </li> */}


                            <li className="nav-item">
                                {(changeHeader === 1)
                                    ?
                                    <a href={'javascript:void(0);'}
                                        onClick={() => scrollTo('#servicios')}
                                        className="nav-link">
                                        <span>Servicios</span>
                                    </a>
                                    :
                                    <Link to='/#servicios'
                                        className="nav-link"><span>Servicios</span>
                                    </Link>
                                }
                            </li>
{/*
                            <li className="nav-item">
                                {(changeHeader === 1)
                                    ?
                                    <a href={'javascript:void(0);'}
                                        onClick={() => scrollTo('#tips')}
                                        className="nav-link">
                                        <span>Tips</span>
                                    </a>
                                    :
                                    <Link to='/#tips'
                                        className="nav-link"><span>Tips</span>
                                    </Link>
                                }
                            </li> */}


                            <li className="nav-item">

                                <a href="https://tienda.yonlui.com"
                                    target="_blank"
                                    className="nav-link">
                                    <span>Tienda</span>
                                </a>

                            </li>
                            <li className="nav-item">
                                {(changeHeader === 1)
                                    ?
                                    <a href={'javascript:void(0);'}
                                        onClick={() => scrollTo('#se_parte')}
                                        className="nav-link">
                                        <span>Únetenos</span>
                                    </a>
                                    :
                                    <Link to='/#se_parte'
                                        className="nav-link"><span>Únetenos</span>
                                    </Link>
                                }
                            </li>
                            <li className="nav-item">
                                {(changeHeader === 1)
                                    ?
                                    <a href={'javascript:void(0);'}
                                        onClick={() => scrollTo('#contact-section')}
                                        className="nav-link">
                                        <span>Contacto</span>
                                    </a>
                                    :
                                    <Link to='/#contact-section'
                                        className="nav-link"><span>Contacto</span>
                                    </Link>
                                }
                            </li>
                            <li className="nav-item icon_header">
                                <span className="">
                                    <a href="https://www.facebook.com/Distribuidorayonluica"
                                        title="Siguenos en facebook"
                                        target="_blank"
                                        className="mr-1">
                                         <img className="rounded-lg" src={`${data.facebook.childImageSharp.fluid.src}`} alt="logo facebook" style={{  width: '30px', height: '30px' }} />
                                        {/* <span className="icon_hea"><FaInstagram className="" color="#fffff" size="1.3em" /></span> */}
                                    </a>
                                    <a href="https://www.instagram.com/distribuidorayonlui/"
                                        title="Siguenos en instagram"
                                        target="_blank"
                                        className="">
                                        <img className="" src={`${data.instagram.childImageSharp.fluid.src}`} alt="logo instagram" style={{  width: '30px', height: '30px' }} />
                                        {/* <span className="icon_hea"><FiFacebook className="" color="#fffff" size="1.3em" /></span> */}
                                    </a>
                                    <a href={btnWs}
                                        title="Siguenos en whatsapp"
                                        target="_blank"
                                        className="ml-1">
                                        <img className="rounded-lg" src={`${data.whatsapp.childImageSharp.fluid.src}`} alt="logo whatsapp" style={{  width: '30px', height: '30px' }} />
                                        {/* <span className="icon_hea"><FaWhatsapp className="" color="#fffff" size="1.3em" /></span> */}
                                    </a>
                                </span>


                            </li>


                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>

    )
}

export default Header

