import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Helmet } from "react-helmet"

// bootsrap
import 'bootstrap/dist/css/bootstrap.min.css'

// styles template
import '../css/owl.carousel.min.css'
import '../css/owl.theme.default.min.css'
import '../css/aos.css'
import '../css/style.css'

import Header from "./header"
import Footer from "./footer"

import {
    MdKeyboardArrowUp,

} from 'react-icons/md';

const Layout = ({ children, changeHeader }) => {



    const [loanding, setLoanding] = useState(true);

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                title
                }
            }
            ws: file(relativePath: { eq: "whatsapp.png" }) {
                childImageSharp {
                    fluid(maxWidth: 50) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

        }
    `)

    useEffect(() => {
        setTimeout(() => {
        setLoanding(false);
        }, 700);
    }, []);

    return (
        <>
        {loanding
            ?
                <div id="ftco-loader" className="show fullscreen"><svg className="circular" width="48px" height="48px"><circle className="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle className="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#022d5d"/></svg></div>
            :
                ''
        }

        <Helmet>
            <link href="https://fonts.googleapis.com/css2?family=Poor+Story&display=swap" rel="stylesheet"></link>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link>
        </Helmet>

        <Header
            siteTitle={data.site.siteMetadata.title}
            changeHeader={changeHeader}
        />

        <main>{children}</main>

        {(changeHeader === 1)
            ?
                <a data-sal="slide-up"  data-sal-duration="2000" data-sal-easing="ease" className='flotante btn_ir_arriba' rel="noopener noreferrer" title="Ir arriba" href={'javascript:void(0);'} onClick={() => scrollTo('#home-section')} ><MdKeyboardArrowUp color="#ffffff" size="2em"/></a>

            :
                <a data-sal="slide-up"  data-sal-duration="2000" data-sal-easing="ease" className='flotante btn_ir_arriba' rel="noopener noreferrer" title="Ir arriba" href={'javascript:void(0);'} onClick={() => scrollTo('.top_btn')} ><MdKeyboardArrowUp color="#ffffff" size="2em"/></a>


        }

        <Footer changeHeader={changeHeader} />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
