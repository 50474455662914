import React from "react"
import { Link } from "gatsby"
import {
    FaMapMarker,
    FaInstagram,
    FaPhone,
} from 'react-icons/fa';
import { FiMail, FiFacebook, } from 'react-icons/fi';
import scrollTo from 'gatsby-plugin-smoothscroll';



const Footer = ({ changeHeader }) => {

    return (
        <>

            <footer className="ftco-footer ftco-section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md">
                            <div className="ftco-footer-widget mb-4">
                                <h2 className="ftco-heading-2"> Distribuidora Yonlui, c.a.</h2>
                                <p>Tenemos el firme propósito de ofrecer a nuestros aliados comerciales, un sistema de distribución eficiente donde garantice el crecimiento esperado para sus marcas.</p>
                                <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                                    <li className="" ><a href="https://www.instagram.com/distribuidorayonlui/" title="Siguenos en Instagram" target="_blank"><FaInstagram className="icon_redes" color="#0b3b99" size="2em" /></a></li>
                                    <li className="" ><a href="https://www.facebook.com/Distribuidorayonluica" title="Siguenos en Facebook" target="_blank"><FiFacebook className="icon_redes" color="#0b3b99" size="2em" /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="ftco-footer-widget mb-4 ml-md-4">
                                <h2 className="ftco-heading-2">Links</h2>
                                <ul className="list-unstyled">

                                    <li className="link_footer">
                                        {(changeHeader === 1)
                                            ?
                                            <a href={'javascript:void(0);'}
                                                onClick={() => scrollTo('#nosotros')}
                                                className="nav-link">
                                                <span>Nosotros</span>
                                            </a>
                                            :
                                            <Link to='/#nosotros'
                                                className="nav-link"><span>Nosotros</span>
                                            </Link>
                                        }
                                    </li>
                                    <li className="link_footer">
                                        {(changeHeader === 1)
                                            ?
                                            <a href={'javascript:void(0);'}
                                                onClick={() => scrollTo('#products')}
                                                className="nav-link">
                                                <span>Productos</span>
                                            </a>
                                            :
                                            <Link to='/#products'
                                                className="nav-link"><span>Productos</span>
                                            </Link>
                                        }
                                    </li>

                                    <li className="link_footer">
                                        {(changeHeader === 1)
                                            ?
                                            <a href={'javascript:void(0);'}
                                                onClick={() => scrollTo('#servicios')}
                                                className="nav-link">
                                                <span>Servicios</span>
                                            </a>
                                            :
                                            <Link to='/#servicios'
                                                className="nav-link"><span>Servicios</span>
                                            </Link>
                                        }
                                    </li>

                                    <li className="link_footer">
                                        <a href="https://tienda.yonlui.com"
                                            target="_blank"
                                            className="nav-link">
                                            <span>Tienda</span>
                                        </a>
                                    </li>

                                    <li className="link_footer">
                                        {(changeHeader === 1)
                                            ?
                                            <a href={'javascript:void(0);'}
                                                onClick={() => scrollTo('#se_parte')}
                                                className="nav-link">
                                                <span>Únetenos</span>
                                            </a>
                                            :
                                            <Link to='/#se_parte'
                                                className="nav-link"><span>Únetenos</span>
                                            </Link>
                                        }
                                    </li>




                                </ul>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="ftco-footer-widget mb-4">
                                <h2 className="ftco-heading-2">Productos</h2>
                                <ul className="list-unstyled">
                                    <li className="link_footer">
                                        {(changeHeader === 1)
                                            ?
                                            <a href={'javascript:void(0);'}
                                                onClick={() => scrollTo('#products')}
                                                className="nav-link">
                                                <span>Chesee Tris</span>
                                            </a>
                                            :
                                            <Link to='/#products'
                                                className="nav-link"><span>Chesee Tris </span>
                                            </Link>
                                        }
                                    </li>
                                    <li className="link_footer">
                                        {(changeHeader === 1)
                                            ?
                                            <a href={'javascript:void(0);'}
                                                onClick={() => scrollTo('#products')}
                                                className="nav-link">
                                                <span>Club Social </span>
                                            </a>
                                            :
                                            <Link to='/#products'
                                                className="nav-link"><span>Club Social </span>
                                            </Link>
                                        }
                                    </li>
                                    <li className="link_footer">
                                        {(changeHeader === 1)
                                            ?
                                            <a href={'javascript:void(0);'}
                                                onClick={() => scrollTo('#products')}
                                                className="nav-link">
                                                <span>Arroz Superior</span>
                                            </a>
                                            :
                                            <Link to='/#products'
                                                className="nav-link"><span>Arroz Superior</span>
                                            </Link>
                                        }
                                    </li>
                                    <li className="link_footer">
                                        {(changeHeader === 1)
                                            ?
                                            <a href={'javascript:void(0);'}
                                                onClick={() => scrollTo('#products')}
                                                className="nav-link">
                                                <span>Leche Campestre</span>
                                            </a>
                                            :
                                            <Link to='/#products'
                                                className="nav-link"><span>Leche Campestre </span>
                                            </Link>
                                        }
                                    </li>
                                    <li className="link_footer">
                                        {(changeHeader === 1)
                                            ?
                                            <a href={'javascript:void(0);'}
                                                onClick={() => scrollTo('#products')}
                                                className="nav-link">
                                                <span>Stadium Display</span>
                                            </a>
                                            :
                                            <Link to='/#products'
                                                className="nav-link"><span>Stadium Display</span>
                                            </Link>
                                        }</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="ftco-footer-widget mb-4">
                                <h2 className="ftco-heading-2">¿Tienes dudas?</h2>
                                <div className="block-23 mb-3">
                                    <ul>
                                        <li><FaMapMarker color="#0b3b99" size="1.2em" /><span className="text">&nbsp; Calle 33 esquina Avenida 27, Primer Piso Sector Andrés Bello Acarigua Estado Portuguesa.</span></li>
                                        <li><a href="tel://4121645982" ><FaPhone color="#0b3b99" size="1.2em" /><span className="text">&nbsp; + 58 412-1645-982</span></a></li>
                                        <li><a href="mailto:contactoventas@yonlui.com"><FiMail color="#0b3b99" size="1.2em" /><span className="text"> contactoventas@yonlui.com</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">

                            <p>
                                Copyright &copy; 2020 - Todos los derechos reservados por <a href="/" className="mb-2" target="_blank" rel="noopener noreferrer"> Distribuidora Yonlui, c.a.</a>

                                &nbsp; | Desarrollado por <a href="https://metalinks.com.ve" target="_blank" rel="noopener noreferrer"> <b>Metalinks, c.a.</b> </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>


        </>

    )
}

export default Footer
